import { computed, Injectable, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GeneralErrorModalComponent } from '../shared/general-error-modal/general-error-modal.component';

export enum MatchEnum {
  NO_MATCH = 'no-match',
  PARTIAL_MATCH = 'partial-match',
  MATCH = 'match',
}

@Injectable()
export class RegistrationService {
  private readonly _matchData = signal<any>(undefined);
  matchData = computed(this._matchData);

  constructor(
    private readonly navCtrl: NavController,
    private readonly httpClient: HttpClient,
    private readonly modalController: ModalController,
  ) {}

  updateMatchData(newData: any): void {
    this._matchData.update((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }

  async matchPatient(data: any, token: string): Promise<any> {
    try {
      const res: any = await firstValueFrom(
        this.httpClient.post(`${environment.ollaApiUrl}/api/supertokens/patient/match`, data, {
          headers: {
            'content-type': 'application/json',
            'X-TENANT-ID': environment.orgSlug,
            Authorization: `Bearer ${token}`,
          },
        }),
      );

      let matchData = { ...res };
      if (!matchData || matchData.question || Object.keys(matchData).length === 0) {
        matchData = {
          ...matchData,
          patient: {
            firstName: data.patientDetails.firstName,
            lastName: data.patientDetails.lastName,
            dateOfBirth: data.patientDetails.dateOfBirth,
            sexAssignedAtBirth: data.patientDetails.sexAssignedAtBirth,
          },
        };
      }
      this.updateMatchData(matchData);

      const matchEnum = this.doesPatientMatch(res);
      switch (matchEnum) {
        case MatchEnum.MATCH:
          await this.navCtrl.navigateForward(['registration/contact-confirmation']);
          break;
        case MatchEnum.PARTIAL_MATCH:
          await this.navCtrl.navigateForward(['registration/partial-match']);
          break;
        case MatchEnum.NO_MATCH:
          await this.navCtrl.navigateForward(['registration/no-match']);
          break;
      }

      return res;
    } catch (res: any) {
      if (res?.error?.error?.code) {
        const code = res.error.error.code;
        this.updateMatchData({ patient: data.patientDetails });
        switch (code) {
          case 1000:
            await this.navCtrl.navigateForward(['registration/already-verified']);
            break;
          case 1001:
          case 1004:
          case 1005:
          case 1006:
            await this.navCtrl.navigateForward(['registration/unable-to-match']);
            break;
        }
        return undefined;
      } else {
        return res;
      }
    }
  }

  doesPatientMatch(matchData: any): MatchEnum {
    if (matchData?.question) {
      return MatchEnum.PARTIAL_MATCH;
    }
    if (matchData?.patient) {
      return MatchEnum.MATCH;
    }
    return MatchEnum.NO_MATCH;
  }

  updatePatientData(data: any): void {
    this._matchData.update((oldData: any) => ({
      ...oldData,
      patient: {
        ...oldData.patient,
        ...data,
      },
    }));
  }

  async openGeneralErrorModal(message: string): Promise<void> {
    const modal = await this.modalController.create({
      component: GeneralErrorModalComponent,
      componentProps: {
        message,
      },
    });
    await modal.present();
  }
}
